<template>
  <div class="about">
    <About></About>
  </div>
</template>

<script>
import About from "@/components/About";

export default {
  name: "AboutView",
  components: {
    About
  },
  mounted() {
    this.$gtag.pageview({
      page_title: this.$route.name,
      page_path: this.$route.fullPath
    });
  }
};
</script>
